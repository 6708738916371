import React, { useState, useEffect, useRef } from 'react';
import {
  db
} from "../../firebase";

import {
  collection,
  getDocs,
} from "firebase/firestore";
import {
  ImageThumbtack,
  Wrapper,
  CollectionTT,
  StyledCarousel,
  TTWrapper,
  TTLink,
  CollectionTTS,
  ServicesH1,
  TTContainer

} from './ThumbtElements';

import 'reactjs-popup/dist/index.css';


const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];
const Thumbtack = () => {

    const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    loadAllImages();
    
  }, []);

  const loadAllImages = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "Thumbtack"));
      const currImages = querySnapshot.docs.map((doc) => doc.data().imageUrl);
      setImages(currImages);
    } catch (error) {
      console.error("Error loading images:", error);
      
    }
  };



  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  const carouselRef = useRef(null);
  let resetTimeout; 

  return (
    <TTContainer id="thumbtack">
    <ServicesH1>Reviews Thumbtack</ServicesH1>
    <TTWrapper>
                
            <CollectionTTS itemsToShow={0}>
                {images && (
                    <Wrapper>
                        <ImageThumbtack
                        src={images[0]} // Display the first image
                        onClick={() => openModal(images[0])}
                    />
                    </Wrapper>
                )}  
            </CollectionTTS>
        <StyledCarousel 
            ref={carouselRef}
            enableAutoPlay={true}
            autoPlaySpeed={4000}
            isRTL={false}
            onNextEnd={({ index }) => {
                clearTimeout(resetTimeout);
                resetTimeout = setTimeout(() => {
                    carouselRef?.current?.goTo(0);
                }, 4000); // same time
            }}
        >
            <CollectionTT itemsToShow={1}>
                {images && (
                    <Wrapper>
                        <ImageThumbtack
                        src={images[1]} // Display the first image
                        onClick={() => openModal(images[1])}
                    />
                    </Wrapper>
                )}  
            </CollectionTT>
            <CollectionTT itemsToShow={2}>
                {images && (
                    <Wrapper>
                        <ImageThumbtack
                        src={images[2]} // Display the first image
                        onClick={() => openModal(images[2])}
                    />
                    </Wrapper>
                )}  
            </CollectionTT>
            <CollectionTT itemsToShow={3}>
                {images && (
                    <Wrapper>
                        <ImageThumbtack
                        src={images[3]} // Display the first image
                        onClick={() => openModal(images[3])}
                    />
                    </Wrapper>
                )}  
            </CollectionTT>
            <CollectionTT itemsToShow={4}>
                {images && (
                    <Wrapper>
                        <ImageThumbtack
                        src={images[4]} // Display the first image
                        onClick={() => openModal(images[4])}
                    />
                    </Wrapper>
                )}  
            </CollectionTT>
            <CollectionTT itemsToShow={5}>
                {images && (
                    <Wrapper>
                        <ImageThumbtack
                        src={images[5]} // Display the first image
                        onClick={() => openModal(images[5])}
                    />
                    </Wrapper>
                )}  
            </CollectionTT>
            <CollectionTT itemsToShow={6}>
                {images && (
                    <Wrapper>
                        <ImageThumbtack
                        src={images[6]} // Display the first image
                        onClick={() => openModal(images[6])}
                    />
                    </Wrapper>
                )}  
            </CollectionTT>
            <CollectionTT itemsToShow={7}>
                {images && (
                    <Wrapper>
                        <ImageThumbtack
                        src={images[7]} // Display the first image
                        onClick={() => openModal(images[7])}
                    />
                    </Wrapper>
                )}  
            </CollectionTT>
            <CollectionTT itemsToShow={8}>
                {images && (
                    <Wrapper>
                        <ImageThumbtack
                        src={images[8]} // Display the first image
                        onClick={() => openModal(images[8])}
                    />
                    </Wrapper>
                )}  
            </CollectionTT>
            <CollectionTT itemsToShow={9}>
                {images && (
                    <Wrapper>
                        <ImageThumbtack
                        src={images[9]} // Display the first image
                        onClick={() => openModal(images[9])}
                    />
                    </Wrapper>
                )}  
            </CollectionTT>
            <CollectionTT itemsToShow={10}>
                {images && (
                    <TTLink to="https://www.thumbtack.com/nj/kearny/electrical-repairs/power-up-general-solution/service/419722667307835398?utm_medium=web&utm_source=txt&surface=sp">
                        <ImageThumbtack
                        src={images[10]} // Display the first image
                        onClick={() => openModal(images[10])}
                    />
                    </TTLink>
                )}  
            </CollectionTT>
        </StyledCarousel>
    </TTWrapper>
    </TTContainer>
  );
};

export default Thumbtack;
