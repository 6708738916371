import React, { useState, useEffect,useRef } from 'react';
import {
  db
} from "../../firebase";

import {
  collection,
  getDocs,
} from "firebase/firestore";
import {
  ImageClients,
  Wrapper,
  CollectionC,
  StyledCarousel,
  TTWrapper,
  TTLink,
  ServicesH1,
  TTContainer

} from './ClientsElements';

import 'reactjs-popup/dist/index.css';


const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
    
  ];
const Clients = () => {

    const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    loadAllImages();
    
  }, []);

  const loadAllImages = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "Clients"));
      const currImages = querySnapshot.docs.map((doc) => doc.data().imageUrl);
      setImages(currImages);
    } catch (error) {
      console.error("Error loading images:", error);
      
    }
  };



  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };
  
  const carouselRef = useRef(null);
  let resetTimeout;

  return (
    <TTContainer id="Clients">
    <ServicesH1>Our Clients</ServicesH1>
    <TTWrapper>
        <StyledCarousel  
         ref={carouselRef}
         enableAutoPlay={true}
         autoPlaySpeed={4000}
         isRTL={false}
         onNextEnd={({ index }) => {
             clearTimeout(resetTimeout);
             resetTimeout = setTimeout(() => {
                 carouselRef?.current?.goTo(0);
             }, 4000); // same time
         }}
        >
            <CollectionC itemsToShow={1} >
                {images && (
                    <Wrapper>
                        <ImageClients
                        src={images[0]} // Display the first image
                    />
                    </Wrapper>
                )}  
                {images && (
                    <Wrapper>
                        <ImageClients
                        src={images[1]} // Display the first image
                    />
                    </Wrapper>
                )}  
                {images && (
                    <Wrapper>
                        <ImageClients
                        src={images[2]} // Display the first image
                    />
                    </Wrapper>
                )}
            </CollectionC>    
            <CollectionC itemsToShow={2}>
                {images && (
                    <Wrapper>
                        <ImageClients
                        src={images[3]} // Display the first image
                    />
                    </Wrapper>
                )}  
            

                {images && (
                    <Wrapper>
                        <ImageClients
                        src={images[4]} // Display the first image
                    />
                    </Wrapper>
                )}  

                {images && (
                    <Wrapper>
                        <ImageClients
                        src={images[5]} // Display the first image
                    />
                    </Wrapper>
                )}  
              </CollectionC >
              <CollectionC itemsToShow={3}>
                {images && (
                    <Wrapper>
                        <ImageClients
                        src={images[6]} // Display the first image
                    />
                    </Wrapper>
                )}  
            

                {images && (
                    <Wrapper>
                        <ImageClients
                        src={images[7]} // Display the first image
                    />
                    </Wrapper>
                )}  

                {images && (
                    <Wrapper>
                        <ImageClients
                        src={images[8]} // Display the first image
                    />
                    </Wrapper>
                )}  

            </CollectionC >
            <CollectionC itemsToShow={4}>
                {images && (
                    <Wrapper>
                        <ImageClients
                        src={images[9]} // Display the first image
                    />
                    </Wrapper>
                )}  
            

                {images && (
                    <Wrapper>
                        <ImageClients
                        src={images[10]} // Display the first image
                    />
                    </Wrapper>
                )}   
            </CollectionC >
            
        </StyledCarousel>
    </TTWrapper>
    </TTContainer>
  );
};

export default Clients;
