import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';

// New css Thumbtack

export const TTContainer = styled.div`
    height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFF;

    @media screen and (max-width: 1280px) {
        height: 450px;
    } 

    @media screen and (max-width: 1024px) {
        height: 800px;
    }


    @media screen and (max-width: 580px) {
        height: 550px;
        
    }

`;

export const TTWrapper = styled.div`
    widht: auto;
    height: auto;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;    

 

    @media screen and (max-width: 1405px) {
        grid-template-columns: 1fr 1fr;
        padding: 0 0px;

    }

    @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr;
        padding: 0 20px;
        
        
    }

    @media screen and (max-width: 414px) {
        grid-template-columns: 1fr;
        grid-gap: 1px;
        padding: 0px;

    }


`;

export const ServicesH1 = styled.h1`
    font-size: 4rem;
    color: #83C441;

    align-items: center;
    text-align: center; 
   

    @media screen and (max-width: 768px) {
        font-size: 3rem;
       
    }    

    @media screen and (max-width: 480px) {
        font-size: 2.5rem;
        
    }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TTLink = styled(Link)`
  display: flex;
  flex-wrap: wrap;
`;

export const ImageThumbtack = styled.img`
    width: 800px;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    position: relative;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1920px) {
        width: 600px;
    }

    @media screen and (max-width: 1280px) {
        width: 500px;
    }

    @media screen and (max-width: 1024px) {
        width: 600px;
    }

    @media screen and (max-width: 768px) {
        width: 550px;
        height: 100%;
    }
    
    @media screen and (max-width: 580px) {
        width: 350px;
        height: 100%;
    }

`;

export const CollectionTT = styled.div`
    display: grid;
    margin-top: 50px;
    position: flex;
    padding: 10px;
    width: 100%;
    height: auto;    
    justify-content: center;
    align-items: center;
    background: #FFF;

    
    @media screen and (max-width: 580px) {
        margin-top:-20px;
     }

`;



export const CollectionTTS = styled.div`
    display: grid;
    position: flex;
    padding: 20px 0px 40px 0px;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    background: #FFF;


`;

export const StyledCarousel = styled(Carousel)`
    .rec.rec-arrow {
        display: none;
    }

    .rec.rec-pagination button {
        display: absolute;
        background-color: transparent;
        border: none;
        width: 10px;
        height: 10px;
        cursor: pointer;
        outline-color: none;
        color: #000;
        transform: translateY(-80%);
        margin-bottom: -10px;
    
        &:focus {
            box-shadow: 0 0 0 2px #83c441;
        }

        &.rec-dot_active {
            box-shadow: 0 0 0 2px #83c441;
        }
`;





