import React, {useState} from "react";
import { Button } from "../ButtonElements";

import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    Column2,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap,
    Img,
} from "./InfoElements";
import {Modal} from '../Modal/index';

const InfoSection = ({
    lightBg, 
    id, 
    imgStart, 
    topLine, 
    lightText,
    headline,
    darkText,
    description,
    buttonLabel,
    img,
    alt,
    primary,
    dark,
    dark2,
}) =>{

    const [showModal, setShowModal] = useState(false);
    const openModal = () => {
        setShowModal(prev => !prev);
    };
    return (

        <InfoContainer lightBg={lightBg} id={id} >
            <InfoWrapper>
                <TopLine>{topLine}</TopLine>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                    <Modal showModal={showModal} setShowModal={setShowModal} />
                        <TextWrapper>
                            
                            
                            <Heading lightText={lightText}>{headline}</Heading>
                            <Subtitle darkText={darkText}>
                                <p>PowerUp Electrical General Solutions, founded by Gustavo Couto in 2018,
                                 has gained an excellent reputation in the Electrical Industry, serving the 
                                 New York Tri-State area and beyond.</p>
                                 &nbsp; 
                                <p>With a focus on sustainable power, 
                                Couto established the company after completing his master's degree in electrical engineering.</p>
                                 &nbsp;
                                <p>Currently employing over 30 specialists, PowerUp EGS offers comprehensive services in electrical installations, 
                                carpentry, construction, painting, tiling, and IT programming for offices, 
                                residential properties, and commercial establishments.</p>
                            </Subtitle>
                            <BtnWrap>
                            <Button onClick={openModal}
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact="true"
                            offset={-80}
                            primary={primary ? 1 : 0}
                            dark={dark ? 1 : 0}
                            dark2={dark2 ? 1 : 0}
                          
                            >{buttonLabel}</Button> 
                            </BtnWrap>
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <Img src={img} alt={alt}/>
                        </ImgWrap>
                    </Column2>

                    </InfoRow>
            </InfoWrapper>
        </InfoContainer>
    );
};

export default InfoSection;
