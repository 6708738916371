import React, {useState, useEffect} from 'react'
import { 
    ServicesContainer, 
    ServicesH1, 
    ServicesWrapper, 
    ServicesCard, 
    ServicesIcon, 
    ServicesH2, 
    ServicesH3,
    Icon1

} from './ServicesElements'
import {Modal} from '../Modal/index';
import { collectionGroup, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";



const Services = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [images, setImages] = useState({});

  const openModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  useEffect(() => {
    loadAllImages();
  }, []);

  const loadAllImages = async () => {
    try {
      const collections = [
        'KFC River Head',
        'Nick House',
        'It Rack',
        'Tiling',
      ];

      const fetchImageUrls = async (collection) => {
        const querySnapshot = await getDocs(query(collectionGroup(db, collection)));
        return querySnapshot.docs.map((doc) => doc.data().imageUrl);
      };

      const imagePromises = collections.map((collection) => fetchImageUrls(collection));
      const imageArrays = await Promise.all(imagePromises);

      const imagesObj = {};
      for (let i = 0; i < collections.length; i++) {
        imagesObj[collections[i]] = imageArrays[i];
      }

      setImages(imagesObj);
    } catch (error) {
      console.error('Error loading images:', error);
    }
  };
    
  return (
    <ServicesContainer id="services">
        <ServicesH3>QUALITY AND EXCELLENCE</ServicesH3>
        <ServicesH1>Our Services</ServicesH1>
        <Modal showModal={showModal} setShowModal={setShowModal} modalContent={modalContent} />  
        <ServicesWrapper>
          {/* Electrical */}
            <ServicesCard>
                <ServicesIcon src={images['Nick House'] && images['Nick House'][3]} onClick={() => openModal('Electrical')}/>
                <ServicesH2>Electrical Installation</ServicesH2>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon src={images['KFC River Head'] && images['KFC River Head'][5]} />
                <ServicesH2>Carpentry</ServicesH2>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon src={images['KFC River Head'] && images['KFC River Head'][5]} />
                <ServicesH2>Drywall Construction</ServicesH2>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon  src={images['KFC River Head'] && images['KFC River Head'][5]} onClick={() => openModal('Painting')} />
                <ServicesH2>Interior Painting</ServicesH2>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon src={images['Tiling'] && images['Tiling'][5]}onClick={() => openModal('Tiling')} />
                <ServicesH2>Tiling</ServicesH2>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon src={images['It Rack'] && images['It Rack'][5]} onClick={() => openModal('ITProgramming')} />
                <ServicesH2>IT Programming</ServicesH2>
            </ServicesCard>
          
        </ServicesWrapper>
    </ServicesContainer>
    
  )
}

export default Services